<template>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.0//EN" "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
    <!-- <div class="icon-sun">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2.5V4.5M12 20.5V22.5M4 12.5H2M6.31412 6.81412L4.8999 5.3999M17.6859 6.81412L19.1001 5.3999M6.31412 18.19L4.8999 19.6042M17.6859 18.19L19.1001 19.6042M22 12.5H20M17 12.5C17 15.2614 14.7614 17.5 12 17.5C9.23858 17.5 7 15.2614 7 12.5C7 9.73858 9.23858 7.5 12 7.5C14.7614 7.5 17 9.73858 17 12.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span class="icon-title">{{title}}</span>
    </div> -->
</template>

<script>
    export default {
        props: {
            width: {
                type: String,
                required: false
            },
            height: {
                type: String,
                required: false
            },
            title: {
                type: String,
                required: false,
                default: ''
            }
        }
    };
</script>

<style scoped>
    .icon-sun {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 5px;

        &:hover{
            cursor: pointer;
        }
    }

    .icon-title {
        font-size: 15px;
    }
</style>